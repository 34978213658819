import Link from "next/link";
import Image from "next/image";
import * as React from "react";
import { Nav } from "@/types/index";
import NavSubMenu from "./NavSubMenu";
import styles from "./styles.module.css";

const DesktopNav: React.FC<{
	hideMenu: Boolean;
	logoLink: string;
	nav: Nav;
}> = ({ hideMenu, logoLink, nav }) => {
	// Render the first level Nav options.
	const navItems = nav.map((item: any) => {
		return (
			<li key={item.title}>
				<span>
					<Link href={item.href}>{item.title}</Link>
					{item.subMenu.length > 0 ? (
						<div className={styles.nav__submenu}>
							<NavSubMenu subMenu={item.subMenu} />
						</div>
					) : (
						""
					)}
				</span>
			</li>
		);
	});

	return (
		<div className="container position-relative">
			<div
				className={`d-flex flex-fill p-3 ${
					hideMenu ? "justify-content-center" : ""
				}`}
			>
				<Link href={logoLink} className={styles.logo_dark}>
					<Image src="/logos/cc-logo.svg" alt="logo" width={250} height={70} />
				</Link>

				{!hideMenu && (
					<div className={styles.nav}>
						<ul>
							{navItems}
							<li>
								<div>
									<Link
										href="/journey/broadband"
										className={`${styles.call_button}`}
									>
										REQUEST A CALL
									</Link>
								</div>
							</li>
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default DesktopNav;
