import * as React from "react";
import { Button } from "../Button";
import styles from "./styles.module.css";

export const ButtonGroup: React.FC<{
	isLoading?: Boolean;
	onClick?: (evt: any) => void;
	chooseOption: (evt: any, index: number) => void;
	items: Array<string | undefined>;
	selectedOption: number | null;
}> = ({ items, onClick, isLoading = false, chooseOption, selectedOption }) => {
	let itemsList = items.map(function (value, index) {
		return (
			<div className="mb-3 col-12 col-sm-8 col-md-6 mx-auto" key={index}>
				<button
					className={`button text-center ${
						selectedOption === index ? styles.chosen : styles.button
					}`}
					name={value ?? ""}
					type="button"
					onClick={(evt) => chooseOption(evt, index)}
				>
					{isLoading ? (
						<>
							<span
								className="spinner-border spinner-border-sm"
								role="status"
								aria-hidden="true"
							></span>
							<span className="visually-hidden">Loading...</span>
						</>
					) : (
						value ?? ""
					)}
				</button>
			</div>
		);
	});
	return (
		<>
			{isLoading ? (
				<div className="d-flex justify-content-center">
					<div className="spinner-border" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			) : (
				itemsList
			)}
			<div className={`${styles.button__fixed} mx-auto mt-4`}>
				<Button name={"Next"} type="button" title={"Next"} onClick={onClick} />
			</div>
		</>
	);
};

export default ButtonGroup;
