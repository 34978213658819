import * as React from "react";
import styles from "./styles.module.css";

export const Heading: React.FC<{
	title: string;
	isBold: boolean;
	variant: number;
}> = ({ title, isBold, variant }) => {
	if (variant < 1 || variant > 6) {
		throw "Invalid variant supplied.";
	}

	if (variant === 1) {
		return (
			<h1 className={`${isBold ? styles.bold : ""} ${styles["h" + variant]}`}>
				{title}
			</h1>
		);
	} else if (variant === 2) {
		return (
			<h2 className={`${isBold ? styles.bold : ""}   ${styles["h" + variant]}`}>
				{title}
			</h2>
		);
	} else if (variant === 3) {
		return (
			<h3 className={`${isBold ? styles.bold : ""} ${styles["h" + variant]}`}>
				{title}
			</h3>
		);
	} else if (variant === 4) {
		return (
			<h4 className={`${isBold ? styles.bold : ""} ${styles["h" + variant]}`}>
				{title}
			</h4>
		);
	} else if (variant === 5) {
		return (
			<h5 className={`${isBold ? styles.bold : ""} ${styles["h" + variant]}`}>
				{title}
			</h5>
		);
	} else if (variant === 6) {
		return (
			<h6 className={`${isBold ? styles.bold : ""} ${styles["h" + variant]}`}>
				{title}
			</h6>
		);
	} else {
		throw "Invalid variant supplied.";
	}
};

export default Heading;
