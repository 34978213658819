import Pill from "../Pill";
import * as React from "react";

export const PillList: React.FC<{
	items: Array<string>;
}> = ({ items }) => {
	return (
		<div className="d-flex justify-content-between">
			{items.map(function (value, index) {
				return (
					<span key={index}>
						<Pill name={value} />
					</span>
				);
			})}
		</div>
	);
};

export default PillList;
