import * as React from "react";

type FormInput = {
	name: string;
	value: string | number | undefined;
	handleChange: (evt: React.SyntheticEvent) => void;
	placeholder: string;
	isRequired: boolean;
};

export const FormInput: React.FC<FormInput> = ({
	name,
	value,
	handleChange,
	placeholder,
}) => {
	return (
		<input
			className="form-control p-3"
			name={name}
			value={value}
			onChange={handleChange}
			placeholder={placeholder}
		/>
	);
};

export default FormInput;
