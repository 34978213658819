import * as React from "react";

type Button = {
	name: string;
	type: "submit" | "button";
	title: string;
	onClick?: (evt: any) => void;
	isLoading?: Boolean;
};

export const Button: React.FC<Button> = ({
	name,
	type,
	title,
	onClick,
	isLoading = false,
}) => {
	return (
		<button
			className={`button button__gradient w-100`}
			name={name}
			type={type}
			onClick={onClick}
		>
			{isLoading ? (
				<>
					<span
						className="spinner-border spinner-border-sm"
						role="status"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Loading...</span>
				</>
			) : (
				title
			)}
		</button>
	);
};

export default Button;
