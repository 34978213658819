import Link from "next/link";
import * as React from "react";
import { NavItem } from "@/types/index";
import styles from "./styles.module.css";
import MobileMenuList from "../MobileMenuList";

const MobileMenuItem: React.FC<{
	item: NavItem;
	isLastChild: Boolean;
	addHref: Boolean;
	activeTab: string;
	setActiveTab: (v: string) => void;
}> = ({ item, isLastChild, addHref, activeTab, setActiveTab }) => {
	// onClick handler to set/unset this
	// Component as the Active Tab in the menu.
	const onClick = () => {
		setActiveTab(activeTab !== item.title ? item.title : "");
	};

	// Determines if the item passed has a SubMenu.
	const hasSubMenu = (item: NavItem): Boolean => {
		return item.subMenu.length > 0;
	};

	const linkContent = (
		<div
			className={`${styles.item__container} ${isLastChild ? "ms-5" : ""}`}
			onClick={onClick}
		>
			<div className={styles.item__label}>{item.title}</div>
			{item.subMenu.length > 0 ? (
				<div className={styles.item__icon}>
					{activeTab !== item.title ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-chevron-down"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-chevron-up"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
							/>
						</svg>
					)}
				</div>
			) : null}
		</div>
	);

	return (
		<li>
			<div
				className={`${styles.li__container} ${
					activeTab === item.title && hasSubMenu(item) ? styles.active__tab : ""
				} ${!hasSubMenu(item) ? "bg-white" : ""}`}
			>
				{addHref ? (
					<Link href={item.href} legacyBehavior>
						{linkContent}
					</Link>
				) : (
					linkContent
				)}
			</div>
			{hasSubMenu(item) ? (
				<ul className={activeTab === item.title ? "d-block bg-blue" : "d-none"}>
					<MobileMenuList items={item.subMenu} isChild={true} />
				</ul>
			) : null}
		</li>
	);
};

export default MobileMenuItem;
