import Link from "next/link";
import * as React from "react";
import styles from "./styles.module.css";
import { NavItem, NavSubMenu } from "@/types/index";

const NavSubMenu: React.FC<{ subMenu: NavSubMenu }> = ({ subMenu }) => {
	// Iterate through the SubMenu type.
	const items = subMenu.map((v) => {
		// Use the Value from the object to build a sub-list.
		for (const [key, value] of Object.entries(v)) {
			const listItems = value.map((navItem: NavItem) => {
				return (
					<li key={navItem.title}>
						<Link href={navItem.href}>{navItem.title}</Link>
					</li>
				);
			});

			// Create the Sub Menu using the key as the Header.
			return (
				<div
					className={`${styles["nav__submenu-container"]} col-lg-3 col-4 gx-3`}
					key={`${key}+2`}
				>
					<div className={styles["nav__submenu-header"]}>{key}</div>
					<ul>{listItems}</ul>
				</div>
			);
		}
	});

	return (
		<div className="container">
			<div className="row">{items}</div>
		</div>
	);
};

export default NavSubMenu;
