import * as React from "react";
import { Nav } from "../../_types";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import styles from "./styles.module.css";

// @TODO maybe move this to Contentful?
const links: Nav = [
	{
		title: "Home",
		href: "/",
		subMenu: [],
	},
	{
		title: "Broadband & TV",
		href: "/broadband",
		subMenu: [
			{
				Broadband: [
					{
						title: "Broadband Deals",
						href: "/broadband",
						subMenu: [],
					},
					{
						title: "Fibre Broadband Deals",
						href: "/broadband/fibre-broadband",
						subMenu: [],
					},
					{
						title: "Wireless Broadband Deals",
						href: "/broadband/wireless-broadband",
						subMenu: [],
					},
					{
						title: "Cheap Broadband Deals",
						href: "/broadband/cheap-broadband",
						subMenu: [],
					},
					{
						title: "Broadband & Home Phone Deals",
						href: "/broadband/broadband-and-home-phone",
						subMenu: [],
					},
					{
						title: "12 Month Broadband Deals",
						href: "/broadband/12-month-broadband-deals",
						subMenu: [],
					},
					{
						title: "Broadband with Free Gifts",
						href: "/broadband/broadband-free-gifts",
						subMenu: [],
					},
				],
			},
			{
				Providers: [
					{
						title: "BT Broadband Deals",
						href: "/broadband/providers/bt",
						subMenu: [],
					},
					{
						title: "TalkTalk Broadband Deals",
						href: "/broadband/providers/talktalk",
						subMenu: [],
					},
					{
						title: "Sky Broadband Deals",
						href: "/broadband/providers/sky",
						subMenu: [],
					},
					{
						title: "Plusnet Broadband Deals",
						href: "/broadband/providers/plusnet",
						subMenu: [],
					},
					{
						title: "Virgin Media Broadband Deals",
						href: "/broadband/providers/virgin-media",
						subMenu: [],
					},
					{
						title: "Vodafone Broadband Deals",
						href: "/broadband/providers/vodafone",
						subMenu: [],
					},
					{
						title: "EE Broadband Deals",
						href: "/broadband/providers/ee",
						subMenu: [],
					},
					{
						title: "NOW Broadband Deals",
						href: "/broadband/providers/now-broadband",
						subMenu: [],
					},
				],
			},
			// {
			// 	"Guides & Tools": [
			// 		{
			// 			title: "Broadband Guides",
			// 			href: "/broadband/guides",
			// 			subMenu: [],
			// 		},
			// 	],
			// },
			{
				TV: [
					{
						title: "Broadband & TV Deals",
						href: "/broadband/tv",
						subMenu: [],
					},
				],
			},
		],
	},

	{
		title: "Mobile",
		href: "/mobile",
		subMenu: [
			{
				"Mobile Phones": [
					{
						title: "Mobile Phone Deals",
						href: "/mobile",
						subMenu: [],
					},
					{
						title: "No Upfront Cost Mobile Deals",
						href: "/mobile/no-upfront-cost",
						subMenu: [],
					},
					{
						title: "Samsung Deals",
						href: "/mobile/samsung",
						subMenu: [],
					},
					{
						title: "iPhone Deals",
						href: "/mobile/apple/iphone",
						subMenu: [],
					},
				],
			},
			{
				"SIM Only": [
					{
						title: "SIM Only Deals",
						href: "/mobile/sim-only",
						subMenu: [],
					},
					{
						title: "Unlimited Data SIM's",
						href: "/mobile/sim-only/unlimited-data",
						subMenu: [],
					},
					{
						title: "5G SIM Only Deals",
						href: "/mobile/sim-only/5g",
						subMenu: [],
					},
					{
						title: "30 Day SIM Only Deals",
						href: "/mobile/sim-only/30-day",
						subMenu: [],
					},
					{
						title: "12 Month SIM Only Deals",
						href: "/mobile/sim-only/12-month",
						subMenu: [],
					},
				],
			},
			{
				"Handset Deals": [
					{
						title: "iPhone 14 Deals",
						href: "/mobile/apple/iphone/14-5g-dual-sim",
						subMenu: [],
					},
					{
						title: "iPhone 14 Pro Deals",
						href: "/mobile/apple/iphone/14-pro-5g-dual-sim",
						subMenu: [],
					},
					{
						title: "iPhone 14 Pro Max Deals",
						href: "/mobile/apple/iphone/14-pro-max-5g-dual-sim",
						subMenu: [],
					},
					{
						title: "Samsung Galaxy S23 Deals",
						href: "/mobile/samsung/galaxy/s23-5g-dual-sim",
						subMenu: [],
					},
					{
						title: "Samsung Galaxy S22 Deals",
						href: "/mobile/samsung/galaxy/s22-5g",
						subMenu: [],
					},
					{
						title: "Samsung Z Fold 4 Deals",
						href: "/mobile/samsung/galaxy/z-fold4-5g",
						subMenu: [],
					},
					{
						title: "Samsung Z Flip 4 Deals",
						href: "/mobile/samsung/galaxy/z-flip4-5g",
						subMenu: [],
					},
				],
			},
		],
	},
	{
		title: "Energy",
		href: "/energy",
		subMenu: [],
	},
	{
		title: "Insurance",
		href: "/insurance",
		subMenu: [
			{
				"Motoring Insurance": [
					{
						title: "Car Insurance",
						href: "/insurance/car-insurance",
						subMenu: [],
					},
					{
						title: "Van Insurance",
						href: "/insurance/van-insurance",
						subMenu: [],
					},
					{
						title: "Bicycle Insurance",
						href: "/insurance/bicycle-insurance",
						subMenu: [],
					},
				],
			},
			{
				"Lifestyle Insurance": [
					{
						title: "Home Insurance",
						href: "/insurance/home-insurance",
						subMenu: [],
					},
					{
						title: "Pet Insurance",
						href: "/insurance/pet-insurance",
						subMenu: [],
					},
					{
						title: "Travel Insurance",
						href: "/insurance/travel-insurance",
						subMenu: [],
					},
				],
			},
		],
	},
];

const Nav: React.FC<{
	hideMenu?: Boolean;
	logoLink?: string;
	theme?: "dark" | "light";
}> = ({ hideMenu = false, logoLink = "/", theme = "light" }) => {
	return (
		<nav className={`${styles.nav}`}>
			<div
				className={`d-none d-lg-block ${
					theme === "dark" ? styles.dark__theme : ""
				}`}
			>
				<DesktopNav hideMenu={hideMenu} logoLink={logoLink} nav={links} />
			</div>
			<div
				className={`d-block d-lg-none ${
					theme === "dark" ? styles.dark__theme : ""
				}`}
			>
				<MobileNav hideMenu={hideMenu} logoLink={logoLink} nav={links} />
			</div>
		</nav>
	);
};

export default Nav;
