import * as React from "react";
import MobileMenuItem from "../MobileMenuItem";
import { Nav, NavItem, NavSubMenu } from "@/types/index";
import { ReactElement } from "react-markdown/lib/react-markdown";

const MobileMenuList: React.FC<{
	items: Nav | NavSubMenu;
	isChild: Boolean;
}> = ({ items, isChild }) => {
	const [openTab, setOpenTab] = React.useState<string>("");

	// Detects if the items prop is of a NavItem type.
	const isNav = (item: Nav | NavSubMenu): item is Nav => {
		return (item[0] as NavItem).title !== undefined;
	};

	// Determine if the list item be a HREF link.
	const shouldAddHref = (list: Array<any>): Boolean => {
		return (!isChild && list.length === 0) || isLastChild(list);
	};

	// Determine if the list item has any subsequent children.
	const isLastChild = (list: Array<any>): Boolean => {
		return list.length === 0 && isChild;
	};

	let menuList: ReactElement[] = [];

	if (isNav(items)) {
		menuList = items.map((item: NavItem) => {
			return (
				<MobileMenuItem
					key={`${item.title}`}
					item={item}
					addHref={shouldAddHref(item.subMenu)}
					isLastChild={isLastChild(item.subMenu)}
					activeTab={openTab}
					setActiveTab={setOpenTab}
				/>
			);
		});
	} else {
		items.map((v: any) => {
			for (const [key, value] of Object.entries<NavSubMenu>(v)) {
				menuList.push(
					<MobileMenuItem
						key={`${key}`}
						item={{
							title: key,
							href: "",
							subMenu: value,
						}}
						addHref={shouldAddHref(value)}
						isLastChild={isLastChild(value)}
						activeTab={openTab}
						setActiveTab={setOpenTab}
					/>
				);
			}
		});
	}

	return <>{menuList}</>;
};

export default MobileMenuList;
